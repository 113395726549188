import React from 'react'
import { API, graphqlOperation }  from "aws-amplify";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
 
import * as queries from '../graphql/queries';
 

class TeamStats extends React.Component {

state = {
  team : [],
  initialLoad: false
}

async componentDidMount() {
  await this.getTeamStats();
}

async componentDidUpdate() {
  await this.getTeamStats();
}

getTeamStats = async ()  => {
  const userA = this.props.userAttributes;
  if(userA.team && userA.team.id && this.state.initialLoad === false) {
        this.setState({initialLoad: true});
        const result = await API.graphql(graphqlOperation(queries.listUsers,{limit: 1000}));
    if(result.data && result.data.listUsers && result.data.listUsers.items) {
        await this.getUserStats(result.data.listUsers.items);
        //console.log(`componentDidMount with ${JSON.stringify(statistics)}`);
    }
  }
}
getUserStats = async (users) => {
  const myUser = this.props.userAttributes || {};
  //console.log(`My User => ${JSON.stringify(myUser)}`);

  this.setState({team: []});
  const team = users.filter( user => ((user.team.id === myUser.team.id) && user.grade < 13));
  const stats = await team.map( async (user) => {
    const input = { id: user.id };
    const result = await API.graphql(graphqlOperation(queries.getUser, input));
    console.log(`graphql invocation result => ${JSON.stringify(result)}`);
    if(result.data && result.data.getUser) {
         const calcObject = await this.calculateTotal(result.data.getUser);
         //console.log(`calcObject for ${user.id} => ${JSON.stringify(calcObject)}`);
         return calcObject;
    }
  });
  //console.log(`Varsity girls team => ${JSON.stringify(stats)}`);
  return stats;
}

calculateTotal = async userData => {
  if(userData && userData.stats) {
    //console.log(`TeamStats PAGE: calculateTotal with team data`);
    const treG = userData.threePointGoal || 0;
    const twoG = userData.twoPointGoal || 0;
    const freeG = userData.freeThrowGoal || 0;
    const totG = userData.goal || 0;
    let threes = 0;
    let twos = 0;
    let frees = 0;
    let totals = 0;
    let stats = userData.stats.items || [];
    for(let x=0; x<stats.length; x++) {
      let item = stats[x];
      threes += item.threePointShots;
      twos += item.twoPointShots;
      frees += item.freeThrows;
      totals += item.totalShots;
    }
    const threeP = parseFloat(parseFloat((threes / treG) * 100).toFixed(2));
    const twoP = parseFloat(parseFloat((twos / twoG) * 100).toFixed(2));
    const freeP = parseFloat(parseFloat((frees / freeG) * 100).toFixed(2));
    const totP = parseFloat(parseFloat((totals / totG) * 100).toFixed(2));
    const progress = { name: userData.name, 
                      twoProgress: twoP,
                      threeProgress: threeP,
                      freeProgress: freeP,
                      totalProgress: totP,
                      threes: threes,
                      twos: twos,
                      frees: frees,
                      ovrTot: totals };
    let teamStats = this.state.team; 
    teamStats.push(progress);
    this.setState({team: teamStats});                     
    return progress;
  } else {
    //console.log(`TEAM PAGE: Component Did Update no data`);
    return {};
  }

}
render() {
  
    return (
      <div className="p-grid" >
        <div className="p-col-12 p-lg-12" >
          <div className="card card-w-title" >
              <h1>Team Members Progress</h1>
              <div className="content-section implementation">
                    <DataTable value={this.state.team} responsive={true} header="Team Totals"     >
                        <Column field="name" header="Date" sortable={true}/>
                        <Column field="twos" header="2 Pointers" sortable={true}/>
                        <Column field="threes" header="3 Pointers" sortable={true}/>
                        <Column field="frees" header="Free Throws" sortable={true}/>
                        <Column field="ovrTot" header="Total Shots" sortable={true}/>
                    </DataTable>
                </div>     
                  
           </div>
      </div>
    </div>
      
   )
  }
}  


export default (TeamStats);