import React, { Component } from 'react';


export class AppFooter extends Component {

    render() {
       
        return  (
            <div className="layout-footer"  >
                <span className="footer-text" > ©2019  WA Hoops Challenge. All Rights Reserved. </span>
            </div>
        );
    }
}