import React from 'react'
import { API, graphqlOperation }  from "aws-amplify";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import * as queries from '../graphql/queries';

class Coach extends React.Component {

state = {
  visible: false,
  team: [],
  initialLoad: false
}

async componentDidMount() {
  await this.getTeamStats();
}

async componentDidUpdate() {
  await this.getTeamStats();
}

getTeamStats = async ()  => {
  const userA = this.props.userAttributes;
  if(userA.team && userA.team.id && this.state.initialLoad === false) {
        this.setState({initialLoad: true});
        const result = await API.graphql(graphqlOperation(queries.listUsers,{limit: 1000}));
        if(result.data && result.data.listUsers && result.data.listUsers.items) {
            const players = result.data.listUsers.items;
            //console.log(`componentDidMount with ${JSON.stringify(result.data.listUsers.items)}`);
            const team = players.filter( user => ((user.team.id === userA.team.id) && user.grade < 13));
            this.setState({team: team});
            console.log(`componentDidMount with ${JSON.stringify(team)}`);
        }
  }
}

handleClick = event => {
  event.preventDefault();
  this.setState({visible: true});
}
onHide = async event => {
  await this.props.refreshData();
  this.setState({visible: false});
}

render() {
  const { userAttributes } = this.props;
  console.log(`user object = ${JSON.stringify(userAttributes)}`);
  if(userAttributes && userAttributes.team) {
    
    return (
      <div className="p-grid" >
      <div className="p-col-12 p-lg-12" style={{height:"100%"}} >
          <div className="card card-w-title" style={{height:"50%"}}>
                 <h1>Westminster Hoops Challenge</h1>
                  
                  <div className="card">
                      
                      <div className="p-grid">
                            <div className="p-col"><h3>Coach: {userAttributes.team.coach || "unknown"}</h3></div>
                            <div className="p-col">&nbsp;</div>
                      </div>   
                        
                        <div className="content-section implementation">
                                <DataTable value={this.state.team} responsive={true} header="Team Member Goals"     >
                                    <Column field="name" header="Name" sortable={true}/>
                                    <Column field="position" header="Position" sortable={true}/>
                                    <Column field="twoPointGoal" header="2 Point Goal" sortable={true}/>
                                    <Column field="threePointGoal" header="3 Point Goal" sortable={true}/>
                                    <Column field="freeThrowGoal" header="Free Throw Goal" sortable={true}/>
                                </DataTable>
                        </div>     
                       
                  </div>
               </div>
          </div>
      </div>
      
   ) } else {
     return (
      <div className="p-grid" >
      <div className="p-col-12 p-lg-12" style={{height:"100%"}} >
          <div className="card card-w-title" style={{height:"50%"}}>
                 <h1>Westminster Hoops Challenge</h1>
          </div>
      </div>
      </div>
     )
   }
}
}  


export default (Coach);