// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:6d6300a0-d0c3-44ec-bb7d-bcea4b12fb8e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JoTcgRwrp",
    "aws_user_pools_web_client_id": "28rrnalvnjihbib1h6qutgsc0m",
    "aws_appsync_graphqlEndpoint": "https://yelj64xktbd57hv6nc5fbz5cxm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "wahoops-20190420111421-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1l119e77nl93n.cloudfront.net"
};


export default awsmobile;
