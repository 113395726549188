import React from 'react'
import {ProgressBar} from 'primereact/progressbar';
import {Button} from 'primereact/button';
import UserStats from './UserStats';
import {Dialog} from 'primereact/dialog';
import Coach from './Coach';

class Home extends React.Component {

state = {
  visible: false,
  
};



handleClick = event => {
  event.preventDefault();
  this.setState({visible: true});
}
onHide = async event => {
  await this.props.refreshData();
  this.setState({visible: false});
}

render() {
  const { userAttributes } = this.props;
  console.log(`user object = ${JSON.stringify(userAttributes)}`);
  if(userAttributes && userAttributes.team && userAttributes.grade > 12) {
    return (<Coach userAttributes={this.props.userAttributes} />) } 
  else if (userAttributes && userAttributes.team && userAttributes.grade < 13) {
    return (
      <div className="p-grid" >
      <div className="p-col-12 p-lg-12" style={{height:"100%"}} >
          <div className="card card-w-title" style={{height:"50%"}}>
                 <h1>Westminster Hoops Challenge</h1>
                  
                  <div className="card">
                      <div className="p-grid">
                            <div className="p-col"><h3>Name:</h3></div>
                            <div className="p-col"><h3>{userAttributes.name}</h3></div>
                      </div>
                      <div className="p-grid">
                            <div className="p-col"><h3>Grade:</h3></div>
                            <div className="p-col"><h3>{userAttributes.grade > 12 ? 'Coach' : userAttributes.grade}</h3></div>
                      </div>
                      <div className="p-grid">
                            <div className="p-col"><h3>Coach:</h3></div>
                            <div className="p-col"><h3>{userAttributes.team.coach || "unknown"}</h3></div>
                      </div>
                      <div className="p-grid">
                        <div className="p-col"><h3 className="centerText">FreeThrow Progress ({userAttributes.frees}/{userAttributes.freeThrowGoal})</h3></div>
                        <div className="p-col"><ProgressBar value={userAttributes.freeProgress} /></div>    
                      </div>
                      <div className="p-grid">
                        <div className="p-col"><h3 className="centerText">2 Pt Progress ({userAttributes.twos}/{userAttributes.twoPointGoal})</h3></div>
                        <div className="p-col"><ProgressBar value={userAttributes.twoProgress} /></div>    
                      </div>
                      <div className="p-grid">
                        <div className="p-col"><h3 className="centerText">3 Pt Progress ({userAttributes.threes}/{userAttributes.threePointGoal})</h3></div>
                        <div className="p-col"><ProgressBar value={userAttributes.threeProgress} /></div>    
                      </div>
                      <div className="p-grid">
                        <div className="p-col"><h3 className="centerText">Overall Progress ({userAttributes.ovrTot}/{userAttributes.goal})</h3></div>
                        <div className="p-col"><ProgressBar value={userAttributes.totalProgress} /></div>    
                      </div>                       
                      <div className="p-grid">
                        <div className="p-col"><h3 className="centerText">Click on the button to enter your shots</h3></div>
                        <div className="p-col"><Button id="entstats" label="Enter Stats" onClick={this.handleClick}/></div>
                      </div>
                      <Dialog key="dialog1" header="Enter Stats" visible={this.state.visible} modal={true} className="gmodal" onHide={this.onHide} maximizable={false}>
                        <UserStats userAttributes={userAttributes} onHide={this.onHide} />
                      </Dialog>
                  </div>
               </div>
          </div>
      </div>
      
   ) } else {
     return (
      <div className="p-grid" >
      <div className="p-col-12 p-lg-12" style={{height:"100%"}} >
          <div className="card card-w-title" style={{height:"50%"}}>
                 <h1>Westminster Hoops Challenge</h1>
          </div>
      </div>
      </div>
     )
   }
}
}  


export default (Home);