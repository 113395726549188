import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class AppTopbar extends Component {
    
    static defaultProps = {
        onToggleMenu: null
    }

    static propTypes = {
        onToggleMenu: PropTypes.func.isRequired
    }

     
    onClick(event) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    };

    render() {
        let session = this.props.session || {};
       
        return (
            
            <div className="layout-topbar clearfix">
            
                <Link to={`/`} className="layout-menu-button" onClick={this.props.onToggleMenu}>
                    <span className="pi pi-bars"> <label >WA Hoops </label></span>
                </Link>
               
             
           
                <div className="layout-topbar-icons">
                   
                   
                    {session && session.username ? 
                    <span> <label >{session.username}</label><Link to={'/'} onClick={ this.props.handleSignout }><i className="pi pi-fw pi-power-off"/>Logout</Link></span>
                    : 
                    null
                    }
                </div>
           
            </div>   
        );
    }
}
export default withRouter(AppTopbar);