// eslint-disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    username
    name
    grade
    goal
    freeThrowGoal
    twoPointGoal
    threePointGoal
    position
    team {
      id
      name
      coach
      users {
        nextToken
      }
    }
    stats {
      items {
        id
        date
        totalShots
        twoPointShots
        threePointShots
        freeThrows
      }
      nextToken
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    username
    name
    grade
    goal
    freeThrowGoal
    twoPointGoal
    threePointGoal
    position
    team {
      id
      name
      coach
      users {
        nextToken
      }
    }
    stats {
      items {
        id
        date
        totalShots
        twoPointShots
        threePointShots
        freeThrows
      }
      nextToken
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    username
    name
    grade
    goal
    freeThrowGoal
    twoPointGoal
    threePointGoal
    position
    team {
      id
      name
      coach
      users {
        nextToken
      }
    }
    stats {
      items {
        id
        date
        totalShots
        twoPointShots
        threePointShots
        freeThrows
      }
      nextToken
    }
  }
}
`;
export const createTeam = `mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    id
    name
    coach
    users {
      items {
        id
        username
        name
        grade
        goal
        freeThrowGoal
        twoPointGoal
        threePointGoal
        position
      }
      nextToken
    }
  }
}
`;
export const updateTeam = `mutation UpdateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
    name
    coach
    users {
      items {
        id
        username
        name
        grade
        goal
        freeThrowGoal
        twoPointGoal
        threePointGoal
        position
      }
      nextToken
    }
  }
}
`;
export const deleteTeam = `mutation DeleteTeam($input: DeleteTeamInput!) {
  deleteTeam(input: $input) {
    id
    name
    coach
    users {
      items {
        id
        username
        name
        grade
        goal
        freeThrowGoal
        twoPointGoal
        threePointGoal
        position
      }
      nextToken
    }
  }
}
`;
export const createStats = `mutation CreateStats($input: CreateStatsInput!) {
  createStats(input: $input) {
    id
    date
    user {
      id
      username
      name
      grade
      goal
      freeThrowGoal
      twoPointGoal
      threePointGoal
      position
      team {
        id
        name
        coach
      }
      stats {
        nextToken
      }
    }
    totalShots
    twoPointShots
    threePointShots
    freeThrows
  }
}
`;
export const updateStats = `mutation UpdateStats($input: UpdateStatsInput!) {
  updateStats(input: $input) {
    id
    date
    user {
      id
      username
      name
      grade
      goal
      freeThrowGoal
      twoPointGoal
      threePointGoal
      position
      team {
        id
        name
        coach
      }
      stats {
        nextToken
      }
    }
    totalShots
    twoPointShots
    threePointShots
    freeThrows
  }
}
`;
export const deleteStats = `mutation DeleteStats($input: DeleteStatsInput!) {
  deleteStats(input: $input) {
    id
    date
    user {
      id
      username
      name
      grade
      goal
      freeThrowGoal
      twoPointGoal
      threePointGoal
      position
      team {
        id
        name
        coach
      }
      stats {
        nextToken
      }
    }
    totalShots
    twoPointShots
    threePointShots
    freeThrows
  }
}
`;
