// eslint-disable
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    name
    grade
    goal
    freeThrowGoal
    twoPointGoal
    threePointGoal
    position
    team {
      id
      name
      coach
      users {
        nextToken
      }
    }
    stats(limit: 1000) {
      items {
        id
        date
        totalShots
        twoPointShots
        threePointShots
        freeThrows
      }
      nextToken
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      name
      grade
      goal
      freeThrowGoal
      twoPointGoal
      threePointGoal
      position
      team {
        id
        name
        coach
      }
      stats {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTeam = `query GetTeam($id: ID!) {
  getTeam(id: $id) {
    id
    name
    coach
    users {
      items {
        id
        username
        name
        grade
        goal
        freeThrowGoal
        twoPointGoal
        threePointGoal
        position
      }
      nextToken
    }
  }
}
`;
export const listTeams = `query ListTeams(
  $filter: ModelTeamFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      coach
      users {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getStats = `query GetStats($id: ID!) {
  getStats(id: $id) {
    id
    date
    user {
      id
      username
      name
      grade
      goal
      freeThrowGoal
      twoPointGoal
      threePointGoal
      position
      team {
        id
        name
        coach
      }
      stats {
        nextToken
      }
    }
    totalShots
    twoPointShots
    threePointShots
    freeThrows
  }
}
`;
export const listStatss = `query ListStatss(
  $filter: ModelStatsFilterInput
  $limit: Int
  $nextToken: String
) {
  listStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      user {
        id
        username
        name
        grade
        goal
        freeThrowGoal
        twoPointGoal
        threePointGoal
        position
      }
      totalShots
      twoPointShots
      threePointShots
      freeThrows
    }
    nextToken
  }
}
`;
