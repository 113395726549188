import React from 'react'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
 

class Stats extends React.Component {

render() {
  const { userAttributes } = this.props;
  console.log(`user object = ${JSON.stringify(userAttributes)}`);
  if(userAttributes && userAttributes.stats) {
    const stats = userAttributes.stats.items || [];
    return (
      <div className="p-grid" >
        <div className="p-col-12 p-lg-12" >
          <div className="card card-w-title" >
              <h1>{userAttributes.name} Stats</h1>
              <div className="content-section implementation">
                    <DataTable value={stats} responsive={true} header="Daily Shot Totals"     >
                        <Column field="date" header="Date" sortable={true}/>
                        <Column field="twoPointShots" header="2 Pointers" sortable={true}/>
                        <Column field="threePointShots" header="3 Pointers" sortable={true}/>
                        <Column field="freeThrows" header="Free Throws" sortable={true}/>
                        <Column field="totalShots" header="Total Shots" sortable={true}/>
                    </DataTable>
                </div>   
                  
           </div>
      </div>
    </div>
    )  
  } else {
    return (
      <div className="p-grid" >
      <div className="p-col-12 p-lg-12" style={{height:"100%"}} >
          <div className="card card-w-title" style={{height:"50%"}}>
                <h1>Westminster Hoops Challenge</h1>
                <p>No stats entered yet</p>
          </div>
      </div>
      </div>
    )}
  }
}  


export default (Stats);