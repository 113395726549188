import React from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {createStats} from '../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify"; 
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const initialState =  {
  totalShots:0,
  twoPointShots:0,
  threePointShots:0,
  freeThrows:0,
  date: new Date()
};
class UserStats extends React.Component {

state = {
  totalShots:0,
  twoPointShots:0,
  threePointShots:0,
  freeThrows:0,
  date: new Date()
};

handleSubmit = async event => {
  try {
    event.preventDefault();
    let myDat = JSON.stringify(this.state.date);
    //console.log(`my dat => ${myDat}`);
    const input = { statsUserId: this.props.userAttributes.sub, 
                    date: myDat.slice(1,11),
                    twoPointShots: this.state.twoPointShots,
                    threePointShots: this.state.threePointShots,
                    freeThrows: this.state.freeThrows,
                    totalShots: this.state.totalShots  
                  };
    console.log(`handleSubmit input => ${JSON.stringify(input)}`);
    
    const result = await API.graphql(graphqlOperation(createStats, { input: input }));
    console.log(`graphql invocation result => ${JSON.stringify(result)}`);
    if(result && result.data) {
      console.log(`success`);
      window.alert(`Successfully added stats for ${myDat.slice(1,11)}`);
      this.setState( initialState );
      this.props.onHide();
    }
  } catch (err) {
    console.error(err);
  }
};

calculateMinusTotals = async (name, made) => {
  const oldValue = parseInt(this.state[name]);
  if((oldValue === 0) && (made === 0)) {
    return;
  }
  console.log(`calculateMinusTotals => ${name} : OldValue => ${oldValue} : made: ${made}`);
  //alert(`calculateMinusTotals => ${name} : OldValue => ${oldValue} : made: ${made}`);
  //console.log(`${JSON.stringify(this.prevState)}`);
  if(parseInt(made) === 1) {
    
  this.setState((prevState) => {
    
    return {
      [name] : prevState[name] + 1 ,
      totalShots: prevState.totalShots + 1
    }
  }) }
  else {
   
    this.setState((prevState) => {
      console.log(`name: ${name} : prevState passed => ${JSON.stringify(prevState)}`);
      return {
      [name] : prevState[name] - 1 ,
      totalShots: prevState.totalShots - 1
      }
    }) }
}

calculatePlusTotals = async (name, made) => {
  const oldValue = parseInt(this.state[name]);
  if((oldValue === 0) && (made === 0)) {
    return;
  }
  console.log(`calculatePlusTotals => ${name} : OldValue => ${oldValue} : made: ${made}`);
  //alert(`calculatePlusTotals => ${name} : OldValue => ${oldValue} : made: ${made}`);
  if(made === 1) {
  this.setState(prevState => {
    return {
    [name] : prevState[name] + 1 ,
    totalShots: prevState.totalShots + 1
    }
  }) } else {
    this.setState(prevState => {
      return {
      [name] : prevState[name] - 1 ,
      totalShots: prevState.totalShots -1
      }
    }) } 
}

handleClick = async (id, e) => {
  const buttName = id;
  e.preventDefault();
  
  //console.log(`name => ${buttName} `);
  //alert(`Clicked by ${JSON.stringify(event.target)}`);
  let name="";
  
  switch (buttName) {
    case "ftplus": name = "freeThrows";
                   await this.calculatePlusTotals(name,1);
                   break;
    case "ftminus": name = "freeThrows";
                   await this.calculatePlusTotals(name,0);
                   break;
    case "treplus": name = "threePointShots";
                   await this.calculatePlusTotals(name,1);
                   break;
    case "treminus": name = "threePointShots";
                   await this.calculatePlusTotals(name,0);
                   break;
    
    case "twoplus": name = "twoPointShots";
                   await this.calculatePlusTotals(name,1);
                   break;
    case "twominus": name = "twoPointShots";
                   await this.calculatePlusTotals(name,0);
                   break;
    
    default:
                  console.log(`default reached without match`);
                  break;
  }
}
handleFtChange = (event) => {
  const freeThrows = this.state.freeThrows;
  const newFTs = parseInt(event.target.value || 0);
  const tShots = this.state.totalShots;
  this.setState({ freeThrows: newFTs,
                  totalShots: parseInt(tShots - freeThrows + newFTs)
   });
};
handle2PtChange = (event) => {
  const twoPointShots = this.state.twoPointShots;
  const newFTs = parseInt(event.target.value || 0);
  const tShots = this.state.totalShots;
  this.setState({ twoPointShots: newFTs,
                  totalShots: parseInt(tShots - twoPointShots + newFTs)
   });
};
handle3PtChange = (event) => {
  const freeThrows = this.state.threePointShots;
  const newFTs = parseInt(event.target.value || 0);
  const tShots = this.state.totalShots;
  this.setState({ threePointShots: newFTs,
                  totalShots: parseInt(tShots - freeThrows + newFTs)
   });
};
render() {
  //const { userAttributes } = this.props;
  //console.log(`user object = ${JSON.stringify(userAttributes)}`);
    return (
      <div className="p-fluid">
         <div className="p-grid">
            <div className="p-col-12">
                <div className="card card-w-title" style={{width:"100%"}}>
                                  
              <form className="form" onSubmit={this.handleSubmit} > 
             
              <div className="p-grid">
                            <div className="p-col-2"> &nbsp;</div>
                            <div className="p-col-6"><label><strong><center>Date For Stats</center></strong></label></div>
                            <div className="p-col-2"> &nbsp;</div>
              </div>
              <div className="p-grid">
                            <div className="p-col-2">&nbsp;</div>
                            <div className="p-col-6"> 
                              <DatePicker className="p-inputtext p-component" selected={this.state.date} onChange={event => this.setState({date:event})}/>
                            </div>
                            <div className="p-col-2">&nbsp;</div>
                           
              </div>
              
              <br />&nbsp;<br />
              <div className="p-grid">
                            <div className="p-col-4"> <label><strong><center>Total Shots</center></strong></label> </div>
                            <div className="p-col-4"><center>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </center> </div>
                            <div className="p-col-3"> <InputText id="totalShots" value={this.state.totalShots}/></div>
              </div>
              <div className="p-grid">
                            <div className="p-col-4"> <label><strong><center>Free Throws</center></strong></label> </div>
                            <div className="p-col-4"><center>
                            <Button id="ftplus" icon="pi pi-plus" onClick={(e) => this.handleClick(`ftplus`, e)}/>
                            &nbsp;&nbsp;
                            <Button id="ftminus" icon="pi pi-minus" onClick={(e) => this.handleClick(`ftminus`, e)}/></center> </div>
                            <div className="p-col-3"> <InputText id="freeThrowsMade" value={this.state.freeThrows} onChange={this.handleFtChange} /></div>
              </div>
             
              <div className="p-grid">
                            <div className="p-col-4"> <label><strong><center>3 Pt Shots</center></strong></label> </div>
                            <div className="p-col-4"><center>
                            <Button id="tokplus" icon="pi pi-plus" onClick={(e) => this.handleClick(`treplus`, e)}/>
                            &nbsp;&nbsp;
                            <Button id="tokminus" icon="pi pi-minus" onClick={(e) => this.handleClick(`treminus`, e)}/></center> </div>
                            <div className="p-col-3"> <InputText id="topOfKey3Made" value={this.state.threePointShots} onChange={this.handle3PtChange} /></div>
              </div>
              
              <div className="p-grid">
                            <div className="p-col-4"> <label><strong><center>2 Pt Shots</center></strong></label> </div>
                            <div className="p-col-4"><center>
                            <Button id="lokplus" icon="pi pi-plus" onClick={(e) => this.handleClick(`twoplus`, e)}/>
                            &nbsp;&nbsp;
                            <Button id="lokminus" icon="pi pi-minus" onClick={(e) => this.handleClick(`twominus`, e)}/></center> </div>
                            <div className="p-col-3"> <InputText id="leftOfKey3Made" value={this.state.twoPointShots} onChange={this.handle2PtChange} /></div>
              </div>
             

              <br />&nbsp;
              <br />&nbsp;
              <br />
              <div className="p-col-12" style={{textAlign:'center'}}>
                   <Button type="submit" label="Submit" style={{marginBottom:'10px'}}  />
              </div>            
                                    
              </form>
           </div>
      </div>
    </div>
    </div>
      
   )
  }
}  


export default (UserStats);