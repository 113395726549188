import React, {Component} from 'react';
import classNames from 'classnames';
import AppTopbar from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import { API, graphqlOperation, Auth, Hub } from "aws-amplify";
import { getUser } from "./graphql/queries";
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/dist/fullcalendar.css';
import './layout/layout.css';
import './App.css';
import { Authenticator, AmplifyTheme } from "aws-amplify-react";
import { Router, Route, Switch, Redirect} from 'react-router-dom';
import createBrowserHistory from "history/createBrowserHistory";
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import logo from './assets/wahoops.png';
import Home from './Views/Home';
import Stats from './Views/Stats';
import TeamStats from './Views/TeamStats';
import {Growl} from 'primereact/growl';
import {getUser as userQuery} from './graphql/queries';
//import {ProgressSpinner} from 'primereact/progressspinner';
//import {BreadCrumb} from 'primereact/breadcrumb';

export const history = createBrowserHistory();

export const UserContext = React.createContext();


class App extends Component {

    state = {
            showSpinner: false,
            user: null,
            userAttributes: null,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: []
        };
    

    componentDidMount() {
        //this.createMenu();
        this.getUserData();
        Hub.listen("auth", this, "onHubCapsule");
    }
    getUserData = async () => {
        const user = await Auth.currentAuthenticatedUser();
        user
          ? this.setState({ user }, () => this.getUserAttributes(this.state.user))
          : this.setState({ user: null });
      };
      getUserAttributes = async authUserData => {
        const attributesArr = await Auth.userAttributes(authUserData);
        const attributesObj = Auth.attributesToObject(attributesArr);

        console.log(`Greg aTtributes => ${JSON.stringify(attributesObj)}`)
        this.setState({ userAttributes: attributesObj });
        await this.getUserQuery(attributesObj);
       
      };
    
      getUserQuery = async attributesObj => {
        //console.log(`getUserQuery aTtributes => ${JSON.stringify(attributesObj)}`)
        const input = { id: attributesObj.sub };
        const limit = 10000;
        //console.log(`getUserQuery input => ${JSON.stringify(input)}`)
        const result = await API.graphql(graphqlOperation(userQuery, input, null, limit));
        //console.log(`graphql invocation result => ${JSON.stringify(result)}`);

        if(result.data && result.data.getUser) {
         
          const calcObject = await this.calculateTotal(result.data.getUser);
          const newProfile = {...attributesObj, ...result.data.getUser, ...calcObject};
          this.setState({ userAttributes: newProfile });
          console.log(`Greg 3 aTtributes => ${JSON.stringify(newProfile)}`)
        } else {
          console.log(`no results`)
        }
        this.createMenu();


        
      };    
      onHubCapsule = capsule => {
          console.log(`${JSON.stringify(capsule.payload)}`);
        switch (capsule.payload.event) {
          case "signIn":
            console.log("signed in");
            this.setState({showSpinner: true});
            this.getUserData();

            break;
          
          case "signOut":
            console.log("signed out");
            this.setState({ user: null });
            break;
          case "signIn_failure":
            console.log("failure on signin");
            this.growl.show({severity: 'error', summary: capsule.payload.data.code || 'Error Message', detail: capsule.payload.data.message || "Validation Failed"});
            break;
          case "completeNewPassword_failure":
            console.log("failure on change password");
            this.growl.show({severity: 'error', summary: capsule.payload.data.code || 'Error Message', detail: capsule.payload.data.message || "Password Validation Failed"});
            break;  
          default:
            return;
        }
      };
      calculateTotal = async userData => {
        if(userData && userData.stats) {
          //console.log(`HOME PAGE: calculateTotal with team data`);
          const treG = userData.threePointGoal || 0;
          const twoG = userData.twoPointGoal || 0;
          const freeG = userData.freeThrowGoal || 0;
          const totG = userData.goal || 0;
          let threes = 0;
          let twos = 0;
          let frees = 0;
          let totals = 0;
          let stats = userData.stats.items || [];
          for(let x=0; x<stats.length; x++) {
            let item = stats[x];
            threes += item.threePointShots;
            twos += item.twoPointShots;
            frees += item.freeThrows;
            totals += item.totalShots;
          }
          const threeP = parseFloat(parseFloat((threes / treG) * 100).toFixed(2));
          const twoP = parseFloat(parseFloat((twos / twoG) * 100).toFixed(2));
          const freeP = parseFloat(parseFloat((frees / freeG) * 100).toFixed(2));
          const totP = parseFloat(parseFloat((totals / totG) * 100).toFixed(2));
      
          const progress = { twoProgress: twoP,
                            threeProgress: threeP,
                            freeProgress: freeP,
                            totalProgress: totP,
                            threes: threes,
                            twos: twos,
                            frees: frees,
                            ovrTot: totals };
          return progress;
        } else {
          console.log(`HOME PAGE: Component Did Update no data`);
          return {};
        }
      
      }
      registerNewUser = async signInData => {
        const getUserInput = {
          id: signInData.signInUserSession.idToken.payload.sub
        };
        const { data } = await API.graphql(graphqlOperation(getUser, getUserInput));
        // if we can't get a user (meaning the user hasn't been registered before), then we execute registerUser
        if (!data.getUser) {
          console.log("no get user data");
        }
      };
    
      handleSignout = async () => {
        try {
          await Auth.signOut();
        } catch (err) {
          console.error("Error signing out user", err);
        }
      };

    onWrapperClick = event => {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu = event => {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick = event => {
        this.menuClick = true;
        setTimeout(() => {this.layoutMenuScroller.moveBar(); }, 500);
    }

    onMenuItemClick = event => {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu = () => {
      if(this.state.userAttributes && this.state.userAttributes.grade) {
        console.log(`Grade => ${this.state.userAttributes.grade}`);
       if(this.state.userAttributes.grade < 13) {
        this.setState({ menu: [
          {label: 'Home', url: '/home', icon: 'pi pi-fw pi-home', command: () => {window.location = '/'}},
          {label: 'Your Stats',  url: '/stats',icon: 'pi pi-fw pi-user',  command: () => {window.location = '/stats'}},
          {label: 'Team Stats',  url: '/teamstats',icon: 'pi pi-fw pi-users',  command: () => {window.location = '/teamstats'}},
        ]});
      } else {
        this.setState({menu: [
          {label: 'Home', url: '/home', icon: 'pi pi-fw pi-home', command: () => {window.location = '/'}},
          {label: 'Team Stats',  url: '/teamstats',icon: 'pi pi-fw pi-users',  command: () => {window.location = '/teamstats'}},
        ]});
      }
      } else {
        console.log(`Grade not found`);
      }
      
        
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop = () => {
        return window.innerWidth > 1024;
    }

    componentDidUpdate = () => {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    render() {
        //let logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';

        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});
        const { user, userAttributes } = this.state;

        return (
            <UserContext.Provider value={{ user, userAttributes }}>
            <Router history={history}>
            <>
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu} session={user} handleSignout={this.handleSignout} />

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                    <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
                        <div className="layout-sidebar-scroll-content" >
                            <div className="layout-logo">
                                <img alt="Logo" src={logo} />
                            </div>
                            <p>&nbsp;</p>
                            <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick} />
                        </div>
                    </ScrollPanel>
                </div>

                <div className="layout-main">
                    <div className="content-section implementation">
                                                <br />
                    </div>
                    <Growl ref={(el) => this.growl = el} />
                    
                    
                    {!user ? <Authenticator theme={theme} style={{width:"50%", margin:"0 auto"}}/> : (
                    <Switch>
                      <Route path="/" exact render={() => <Home userAttributes={userAttributes} refreshData={this.getUserData} /> } />
                      <Route path="/stats" render={() => <Stats userAttributes={userAttributes} /> } />
                      <Route path="/teamstats" render={() => <TeamStats userAttributes={userAttributes} myuser={user} /> } />
                      <Redirect to="/" />
                    </Switch>
                    
                    ) }
                  
                  
                </div>               
                <AppFooter />
                <div className="layout-mask"></div>
            </div>
            </>
        </Router>
      </UserContext.Provider>    
        );
    }
}


const theme = {
    ...AmplifyTheme,
    formSection: {
      ...AmplifyTheme.formSection,
      width: "60%"
    },
    navBar: {
      ...AmplifyTheme.navBar,
      backgroundColor: "#ffc0cb"
    },
    button: {
      ...AmplifyTheme.button,
      backgroundColor: "#492f8d",
      fontFamily: "Roboto_Condensed",
      color: "var(--white)"
    },
    sectionBody: {
      ...AmplifyTheme.sectionBody,
      padding: "5px"
    },
    sectionHeader: {
      ...AmplifyTheme.sectionHeader,
      fontFamily: "Roboto_Condensed",
      backgroundColor: "#492f8d"
    },
    sectionFooterSecondaryContent: {
      ...AmplifyTheme.sectionFooterSecondaryContent,
      display: "none"
      
    }
};

export default App;
