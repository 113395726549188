import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import ScrollToTop from './ScrollToTop';
import config from './aws-exports';
import Amplify from 'aws-amplify';
import registerServiceWorker from './registerServiceWorker';



Amplify.configure(config);

ReactDOM.render(
        <Router>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Router>, 
   
    document.getElementById('root')
);

registerServiceWorker();
